(function(){
    // news
    let setWidth = 767;
    let width = jQuery(window).width();
    let newsList = jQuery('.u-parts-news-list');
    let isNewsSlick = false;

    let newsSlicksetting = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: '<span class="u-parts-news-list-prev"><svg xmlns="http://www.w3.org/2000/svg" width="27" height="9" viewBox="0 0 26.814 9.327"><g transform="translate(-3.186 9.327) rotate(180)"><path d="M-7.849,0-8.7.848l3.21,3.21H-30V5.269H-5.487L-8.7,8.479l.848.848,4.663-4.663Z"/></g></svg></span>',
        nextArrow: '<span class="u-parts-news-list-next"><svg xmlns="http://www.w3.org/2000/svg" width="27" height="9" viewBox="0 0 26.814 9.327"><path d="M-7.849,0-8.7.848l3.21,3.21H-30V5.269H-5.487L-8.7,8.479l.848.848,4.663-4.663Z" transform="translate(30)"/></svg></span>'
    };

    if(width <= setWidth){
        newsList.slick(newsSlicksetting);
        isNewsSlick = true;
    }else if(isNewsSlick !== false) {
        newsList.slick('unslick');
        isNewsSlick = false;
    }

    jQuery(window).resize(function() {
        width = jQuery(window).width();
        if(width <= setWidth){

            if(isNewsSlick === false) {
                newsList.slick(newsSlicksetting);
                isNewsSlick = true;
            }

        }else if(isNewsSlick !== false) {
            newsList.slick('unslick');
            isNewsSlick = false;
        }
    });
}());